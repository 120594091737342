import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPastOrders } from './redux/slices/ordersSlice';
import { fetchAllMenuItems, fetchDBMenuData } from './redux/slices/dbFoodMenuSlice';
import { MenuCategory } from './redux/slices/dbFoodMenuSlice';
import { fetchMe } from './redux/slices/userAuthSlice';
import { fetchBranches } from './redux/slices/branchesSlice';
import { Loading } from './components/Loading';
import { items_icon_paths } from './constants/s3_assets';
import { AppDispatch, useSelector } from './redux/store';

const DataFetcher = ({ children }) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const userAuth = useSelector((state) => state.userAuth);
    const dispatch = useDispatch<AppDispatch>();

    const s3Url = 'https://pizzahut-assets.s3.amazonaws.com/';
    const itemIconsS3Urls = items_icon_paths.map((path) => s3Url + path);

    const imagesUrls = [
        ...itemIconsS3Urls,
        // require('./assets/images/screen-bg/pizzas/2.jpg'),
        // require('./assets/images/screen-bg/menu/1.jpg'),
        // require('./assets/images/screen-bg/pizzas/faded.png'),
    ];

    const preloadImages = (imageUrls) => {
        return Promise.all(
            imageUrls.map((url) => {
                return new Promise<void>((resolve, reject) => {
                    const img = new Image();
                    img.src = url;
                    img.onload = () => resolve();
                    img.onerror = (err) => {
                        console.error(`Failed to load image at URL: ${url}`, err);
                        reject(err);
                    };
                });
            }),
        );
    };

    const fetchDataFunc = async () => {
        await dispatch(fetchMe());
        await dispatch(fetchPastOrders());
        const dbMenuDataAction = (await dispatch(fetchDBMenuData())) as { payload: MenuCategory[] };
        await dispatch(fetchAllMenuItems());
        await dispatch(fetchBranches());
        const dbMenuData = dbMenuDataAction.payload; // Adjust according to your types
        const allBgSrc: string[] = [];
        if (!dbMenuData) {
            //Break the method if DB isn't working
            return;
        }
        dbMenuDataAction.payload.forEach((category) => {
            if (category.bg_src) {
                allBgSrc.push(category.bg_src);
            }
            category.sub_categories.forEach((subCategory) => {
                if (subCategory.bg_src) {
                    allBgSrc.push(subCategory.bg_src);
                }
                subCategory.items.forEach((item) => {
                    if (item.bg_src) {
                        allBgSrc.push(item.bg_src);
                    }
                });
            });
            category.items.forEach((item) => {
                if (item.bg_src) {
                    allBgSrc.push(item.bg_src);
                }
            });
        });
        const allImagesUrls = [...imagesUrls, ...allBgSrc];
        preloadImages(allImagesUrls)
            .then(() => {
                setImagesLoaded(true);
                console.log('Images loaded');
            })
            .catch((err) => {
                console.error('Error preloading images:', err);
                setImagesLoaded(true);
            });

        setDataLoaded(true);
    };

    useEffect(() => {
        // if (userAuth.token) {
        fetchDataFunc();
        // }
    }, []);

    return (
        <>
            {dataLoaded && imagesLoaded ? (
                <div className="w-full h-full p-0 mx-0 my-0">{children}</div>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default DataFetcher;
